import React, { useEffect, useRef } from "react"
import styled from "@emotion/styled"
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import { Flex, Box } from "theme-ui"

const MapContainer = styled(Box)`
  position: relative;
  width: 100%;
  padding-bottom: 31.4%;

  .marker {
    position: relative;
    background: ${(props) => props.theme.colors.dark};
    border-radius: 50%;
    width: 32px;
    height: 32px;

    :after {
      content: "";
      position: absolute;
      top: 25%;
      left: 25%;

      background: ${(props) => props.theme.colors.lightest};
      border-radius: 50%;
      width: 16px;
      height: 16px;
    }
  }
`

const mapBoxToken = process.env.GATSBY_MAPBOX_TOKEN || ""
const mapBoxStyles =
  process.env.GATSBY_MAP_STYLES || "mapbox://styles/mapbox/light-v10"

const DeliveryMap = ({ location }) => {
  const mapContainer = useRef()

  mapboxgl.accessToken = mapBoxToken

  useEffect(() => {
    const { center } = location
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: mapBoxStyles,
      center: center,
      zoom: 16,
    })
    map.scrollZoom.disable()

    const el = document.createElement("div")
    el.className = "marker"

    new mapboxgl.Marker(el).setLngLat(center).addTo(map)

    return () => map.remove()
  }, [location])

  return (
    <Flex sx={{ flexDirection: "column" }}>
      <MapContainer className="map-container" ref={mapContainer} />
    </Flex>
  )
}

export default DeliveryMap
