import React, { useCallback, useEffect, useState } from "react"
import qs from "query-string"

import OrderDone from "../../components/checkout/done"
import SEO from "../../components/seo"
import LoadingSpinner from "../../components/ui/LoadingSpinner"
import { trackMap, trackPurchase } from "../../services/analytics"
import Medusa from "../../services/api"

const mapboxToken = process.env.GATSBY_MAPBOX_TOKEN

const CheckoutDone = ({ location }) => {
  const [order, setOrder] = useState()
  const [fetchingOrder, setFetchingOrder] = useState(true)
  const [mapLocation, setMapLocation] = useState()

  useEffect(() => {
    const initializeOrders = async () => {
      const { o } = qs.parse(location.search)
      if (o) {
        Medusa.orders
          .retrieve(o)
          .then(({ data }) => {
            // Store the order token in localstorage we use this to
            // prevent double analytics calls
            const tokens = JSON.parse(localStorage.getItem("ots")) || []
            if (!tokens.includes(o)) {
              tokens.push(o)
              trackPurchase(data.order)
            }
            localStorage.setItem("ots", JSON.stringify(tokens))

            if (data.order && data.order.customerId) {
              window.analytics.identify(
                data.order.customerId,
                {
                  email: data.order.email,
                },
                {
                  integrations: {
                    MailChimp: false,
                  },
                }
              )
            }

            setOrder(data.order)
            setFetchingOrder(false)

            const checkoutContainer = document.getElementById(
              "klarna-container"
            )
            if (checkoutContainer) {
              const scriptsTags = checkoutContainer.getElementsByTagName(
                "script"
              )
              // This is necessary otherwise the scripts tags are not going to be evaluated
              for (var i = 0; i < scriptsTags.length; i++) {
                const parentNode = scriptsTags[i].parentNode
                const newScriptTag = document.createElement("script")
                newScriptTag.type = "text/javascript"
                newScriptTag.text = scriptsTags[i].text
                parentNode.removeChild(scriptsTags[i])
                parentNode.appendChild(newScriptTag)
              }
            }
          })
          .catch((err) => {
            console.log(err)
            setOrder({
              tooOld: true,
            })
            setFetchingOrder(false)
          })
      }
    }
    initializeOrders()
  }, [location.search])

  useEffect(() => {
    if (!order) return

    const load = async () => {
      const {
        city,
        address_1,
        postal_code,
        province,
        country_code,
      } = order?.shipping_address

      const encodedLocation = encodeURIComponent(
        `${address_1},${postal_code},${city},${province}`
      )

      const fetchUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodedLocation}.json?country=${country_code}&access_token=${mapboxToken}`

      fetch(fetchUrl)
        .then((res) => res.json())
        .then((data) => {
          const location = handleLocation(data)
          setMapLocation(location)
        })
        .catch(() => setMapLocation(null))
    }

    load()
  }, [order, handleLocation])

  const handleLocation = useCallback(
    (data) => {
      const location = data.features[0]
      const willAppear = location.relevance > 0.6

      trackMap({
        order: order,
        location: location,
        accuracy: location.relevance,
        didAppear: willAppear,
      })

      if (willAppear) {
        return location
      }

      return null
    },
    [order]
  )

  return (
    <div>
      <SEO title="Order Completed" />
      {!(order?.id || order?.tooOld) || fetchingOrder ? (
        <LoadingSpinner />
      ) : (
        order && (
          <>
            <OrderDone order={order} location={mapLocation} />
            {order.payments[0] && order.payments[0].provider_id === "klarna" && (
              <div
                id="klarna-container"
                dangerouslySetInnerHTML={{
                  __html: order.payments[0].data.html_snippet,
                }}
              />
            )}
          </>
        )
      )}
    </div>
  )
}

export default CheckoutDone
