import React, { useState } from "react"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"
import { Text, Flex, Box } from "theme-ui"

import CheckoutLayout from "./checkout-layout"
import CartSummary from "./cart-summary"
import { StepBody } from "./checkout-step"

import CheckmarkDone from "../../assets/svg/checkmark-done.svg"
import LeftArrow from "../../assets/svg/arrow-left.svg"
import DownArrow from "../../assets/svg/down-arrow-small.svg"

import Input from "../new-ui/input-field"
import Button from "../new-ui/button"

import Logo from "../../assets/logo.png"
import { formatCartTotal } from "../../utils/prices"
import Medusa from "../../services/api"

import "../../fonts/styles.css"

import {
  Header,
  ControlsContainer,
  Controls,
  Summary,
  CheckoutContainer,
} from "./elements"
import DeliveryMap from "../new-ui/map/delivery-location-map"
import { handleUrlPrefix } from "../../utils/translations-util"

const CheckoutDone = ({ order, location }) => {
  const { t, i18n } = useTranslation()
  const home = handleUrlPrefix(i18n.language, "/")
  const [signupComplete, setSignupComplete] = useState(false)
  const [showCart, toggleCart] = useState(false)

  const renderStatus = () => {
    switch (order.fulfillment_status) {
      case "not_fulfilled":
        return t("confirmed")
      case "fulfilled":
        return t("packing")
      case "partially_fulfilled":
        return "Packing first batch"
      case "partially_shipped":
        return "First batch shipped"
      case "shipped":
        return t("shipped")
      default:
        return null
    }
  }

  const getColorFromStatus = () => {
    switch (order.fulfillment_status) {
      case "not_fulfilled":
      case "fulfilled":
      case "partially_fulfilled":
      case "partially_shipped":
        return "accent"
      case "shipped":
        return "green"
      default:
        return "dark"
    }
  }

  const handleSignup = () => {
    Medusa.newsletter
      .signup({
        ids: {
          user_id: !window.analytics ? "" : window.analytics.user().id(),
          anonymous_id: !window.analytics
            ? ""
            : window.analytics.user().anonymousId(),
        },
        email: order.email,
      })
      .then(() => {
        setSignupComplete(true)
      })
  }

  return (
    <CheckoutLayout>
      <CheckoutContainer>
        <ControlsContainer>
          <Controls>
            <Header>
              <Flex
                py={"16px"}
                sx={{
                  alignItems: "center",
                  justifyContent: [
                    "space-between",
                    "space-between",
                    "flex-start",
                  ],
                  top: 0,
                  position: "sticky",
                  a: { textDecoration: "none" },
                }}
              >
                <Box className="logo" sx={{ height: "100%" }}>
                  <Link to={home}>
                    <img className="logo" alt="Tekla Logo" src={Logo} />
                  </Link>
                </Box>
                <Flex sx={{ justifyContent: "space-between" }}>
                  <Link to={home}>
                    <Flex ml={"2rem"} sx={{ alignItems: "center" }}>
                      <Flex
                        sx={{
                          alignItems: "center",
                        }}
                        mr={"8px"}
                      >
                        <LeftArrow />
                      </Flex>
                      {t("back_to_shop")}
                    </Flex>
                  </Link>
                </Flex>
              </Flex>
              <Flex
                mt={"2rem"}
                sx={{
                  display: ["flex", "flex", "none"],
                  flexDirection: "column",
                }}
              >
                <Flex
                  onClick={() => toggleCart(!showCart)}
                  sx={{ alignItems: "center", justifyContent: "space-between" }}
                >
                  <Flex
                    sx={{
                      alignItems: "center",
                      svg: { transform: showCart && "rotate(180deg)" },
                    }}
                  >
                    <Box mr={1}>{t("view_order_summary")}</Box> <DownArrow />
                  </Flex>
                  <Box sx={{ fontWeight: 600 }}>{formatCartTotal(order)}</Box>
                </Flex>
                {showCart && (
                  <CartSummary
                    hideCodes
                    hidePayOptions
                    cart={order}
                    disableEdit={true}
                  />
                )}
              </Flex>
            </Header>
            <Flex
              sx={{
                flexDirection: "column",
                width: ["100%"],
                bg: ["white", "white", "transparent"],
                px: ["16px", "120px"],
                my: "16px",
              }}
            >
              <Flex sx={{ flexDirection: "column" }}>
                <StepBody active>
                  <Flex sx={{ fontSize: "18px", alignItems: "center" }}>
                    <CheckmarkDone />
                    <Box ml={"0.5rem"}> {t("thank_you_for_ordering")}!</Box>
                  </Flex>
                  <Flex mt={"1rem"} sx={{ fontSize: "12px" }}>
                    {t("thank_you_for_ordering_confirmation")}.
                  </Flex>
                  <Flex
                    sx={{
                      marginTop: "2rem",
                      flexDirection: "column",
                      background: "white",
                    }}
                  >
                    {location && <DeliveryMap location={location} />}
                    <Flex
                      sx={{
                        padding: ["8px 0", "25px"],
                        justifyContent: "space-between",
                      }}
                    >
                      <Flex sx={{ flexDirection: "column" }}>
                        <Text sx={{ fontSize: "14px", color: "dark" }}>
                          {order.shipping_address.address_1},{" "}
                          {order.shipping_address.city},{" "}
                          {order.shipping_address.postal_code}
                        </Text>

                        <Text
                          mt="24px"
                          sx={{
                            fontSize: "12px",
                            fontWeight: 600,
                            color: getColorFromStatus(),
                          }}
                        >
                          {t("status")}: {renderStatus()}
                        </Text>
                      </Flex>
                      <Flex
                        sx={{
                          flexDirection: "column",
                          fontSize: "14px",
                          color: "dark",
                        }}
                      >
                        {order.shipping_methods.some(
                          (method) =>
                            method.shipping_option?.metadata?.transit_time
                        ) && (
                          <>
                            <Text>
                              {t("delivery_estimate")}:{" "}
                              {
                                order.shipping_methods.find(
                                  (method) =>
                                    method.shipping_option?.metadata
                                      ?.transit_time
                                )?.shipping_option.metadata.transit_time
                              }{" "}
                              {t("delivery_business_days")}
                            </Text>
                            <Text mt="8px">
                              {t("carrier")}:{" "}
                              {order.shipping_methods
                                .map((method) => method.shipping_option?.name)
                                .join(", ")}
                            </Text>
                          </>
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex mt={"2rem"} sx={{ flexDirection: "column" }}>
                    <Text sx={{ fontSize: "18px" }}>
                      {t("become_a_loyal_customer")}
                    </Text>
                    <Text
                      mb={"1rem"}
                      mt={"0.5rem"}
                      sx={{
                        maxWidth: "350px",
                        fontSize: "12px",
                      }}
                    >
                      {t("join_loyal_customers")}
                    </Text>
                    {signupComplete ? (
                      <Flex>{t("thank_you_promo_arriving")}</Flex>
                    ) : (
                      <Flex sx={{ width: "100%" }}>
                        <Input disabled value={order.email} />
                        <Button ml={"0.5rem"} onClick={handleSignup}>
                          {t("sign_up")}
                        </Button>
                      </Flex>
                    )}
                  </Flex>
                </StepBody>
              </Flex>
            </Flex>
          </Controls>
        </ControlsContainer>
        <Summary
          sx={{
            display: ["none !important", "none !important", "flex !important"],
          }}
        >
          <CartSummary
            hideCodes
            hidePayOptions
            cart={order}
            disableEdit={true}
          />
        </Summary>
      </CheckoutContainer>
    </CheckoutLayout>
  )
}

export default CheckoutDone
